import styled from 'styled-components';

import { getGridBase, media } from '@utils/styled';

import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import Typography from '@common/components/Typography';

export const AddressOpeningGrid = styled.div`
  ${getGridBase(12)};
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'scale', 5)};
`;

export const ContentColumn = styled.div`
  grid-column: span 12;

  ${media.from800up`
    grid-column: span 6;
  `}
`;

export const DescriptionTitle = styled(Typography).attrs({
  customAs: 'h2',
  variant: 'h5',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const Street = styled(Typography).attrs({
  customAs: 'p',
  variant: 'paragraph1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 6)};
`;

export const ZipCodeCity = styled(Typography).attrs({
  customAs: 'p',
  variant: 'paragraph1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 6)};
`;
export const Phone = styled(Typography).attrs({
  customAs: 'p',
  variant: 'paragraph1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 6)};
`;

export const AddressButton = styled(ButtonLink).attrs({
  variant: BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL,
})`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 8)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 5)};

  a&[href]::after {
    content: none;
    display: none;
  }
`;

export const StoreDescription = styled(Typography).attrs({
  variant: 'paragraph1',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 7)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 5)};
`;

export const OpeningDaysHoursGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  ${({ theme }) => theme.getFluidSpacing('grid-column-gap', 'scale', 2)};
  ${({ theme }) => theme.getFluidSpacing('grid-row-gap', 'scale', 5)};
`;

export const OpeningDay = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: 0;
  font-weight: ${({ isCurrentDay }) => (isCurrentDay ? 'bold' : undefined)};
`;

export const OpeningHoursRow = styled(Typography).attrs({ variant: 'body1' })`
  margin: 0;
  font-variant-numeric: tabular-nums;
  font-weight: ${({ isCurrentDay }) => (isCurrentDay ? 'bold' : undefined)};
`;

export const OpeningHoursContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
`;
