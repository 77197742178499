import styled, { css } from 'styled-components';

import { getThemeTransition } from '@utils/styled';

import { ButtonStyles } from '@common/components/Button/Button.styled';
import { ButtonVariant } from '@common/components/Button/common';

export const StyledAnchor = styled.a<{
  $isExternal?: boolean;
  variant?: ButtonVariant;
}>`
  ${ButtonStyles};
  display: inline-flex;
  justify-content: center;

  ${({ $isExternal }) =>
    $isExternal &&
    css`
      &&&::after {
        content: '';
        display: inline-block;
        align-self: center;
        width: 0.7em;
        height: 0.7em;
        margin-left: 1rem;
        background-image: url('/static/img/arrow-external-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        transition: ${getThemeTransition('transform')};
      }

      &&&:hover::after,
      &&&:focus::after,
      &&&:active::after {
        transform: rotate(45deg);
      }
    `};
`;
