var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cr_xv5YDps8RQ-KLatYEb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { SENTRY_DSN, SENTRY_ENV, BRANCH } = getConfig().publicRuntimeConfig;

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: SENTRY_ENV !== 'local',
  environment: SENTRY_ENV || BRANCH,
  tracesSampleRate: 0.01,
  normalizeDepth: 10,
});
