import styled from 'styled-components';

import {
  centeredGridColumns,
  getGridBase,
  media,
  getThemeColor,
} from '@utils/styled';

import Typography from '@common/components/Typography';
import Pill from '@common/components/Pill';
import { VARIANTS } from '@common/constants/pills';

export const Content = styled.article`
  ${getGridBase(12)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 10)};
`;

export const ContentInner = styled.div`
  ${({ theme, hasImage }) =>
    hasImage && theme.getFluidSpacing('margin-top', 'scale', 3)};
  grid-column: ${centeredGridColumns(12)};
  max-width: 100%;

  ${media.from640up`
    grid-column: ${centeredGridColumns(10)};
  `}

  ${media.from1200up`
    grid-column: ${centeredGridColumns(8)};
  `}
`;

export const Title = styled(Typography).attrs({
  customAs: 'h1',
  variant: 'h2',
})`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 7)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const OpeningHoursRow = styled(Typography).attrs({ variant: 'body1' })`
  margin: 0;
  font-variant-numeric: tabular-nums;
  font-weight: ${({ isCurrentDay }) => (isCurrentDay ? 'bold' : undefined)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.getFluidSpacing('gap', 'scale', 7)};
`;

export const StyledPill = styled(Pill).attrs({ variant: VARIANTS.LIGHT })`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 7)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 6)};
`;

export const SubTitle = styled(Typography).attrs({ variant: 'h4' })`
  margin-top: 0;
  color: ${getThemeColor('bluegrey.60')};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'scale', 3)};
`;
