import React, { ForwardedRef } from 'react';

import { StyledAnchor } from '@common/components/Button/ButtonLink.styled';

import { ButtonVariant } from './common';
import { ButtonContentWrapper } from './Button.styled';

type ButtonLinkProps = {
  children: string;
  href?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  variant?: ButtonVariant;
  isExternal?: boolean;
};

export const ButtonLink = React.forwardRef(
  (
    {
      href,
      children,
      iconLeft,
      iconRight,
      variant,
      isExternal,
      ...props
    }: ButtonLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => (
    <StyledAnchor
      variant={variant}
      href={href}
      ref={ref}
      $isExternal={isExternal}
      {...props}
    >
      <ButtonContentWrapper>
        {iconLeft}
        {children}
        {iconRight}
      </ButtonContentWrapper>
    </StyledAnchor>
  ),
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
