import PropTypes from 'prop-types';
import React from 'react';

import { BUTTON_VARIANTS } from '@common/components/Button/common';

import { ButtonContentWrapper, StyledButton } from './Button.styled';

export const Button = ({
  children,
  iconLeft,
  iconRight,
  type,
  variant,
  className,
  buttonId,
  ...props
}) => (
  <StyledButton
    {...props}
    variant={variant}
    type={type}
    className={className}
    id={buttonId}
  >
    <ButtonContentWrapper>
      {iconLeft}
      {children}
      {iconRight}
    </ButtonContentWrapper>
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  type: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  buttonId: PropTypes.string,
};

Button.defaultProps = {
  children: undefined,
  className: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  variant: BUTTON_VARIANTS.LEVEL_1_GREEN,
  buttonId: undefined,
};

export default Button;
